<template>
  <div :class="$style.categories">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="20rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input @input="handleNameInput" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Отображать в блоке Специальные" prop="isSpecial">
        <el-checkbox v-model="form.isSpecial"></el-checkbox>
      </el-form-item>
      <el-form-item label="Атрибуты" prop="attributes">
        <el-select
          v-model="form.attributes"
          multiple
          placeholder="Выберите"
          @change="change"
          style="width: 100%"
        >
          <el-option
            v-for="item in attributes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="submitForm('form')">Создать</el-button>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications.js'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  mixins: [notifications],
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      attributes: [],
      form: {
        name: '',
        slug: '',
        attributeIDs: [],
        attributes: [],
        orderField: 0
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ],
        orderField: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getAttributeList()
  },
  methods: {
    async getAttributeList() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList({
          limit: this.limit,
          page: this.page
        })
      if (error) return
      this.attributes = value.data
      this.total = value.meta.count

      loading.close()
    },

    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },

    resetForm() {
      this.form = {
        name: '',
        slug: '',
        attributeIDs: [],
        attributes: [],
        isSpecial: false,
        orderField: 0
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const result = await delivery.ProductsCore.GroupsActions.create(
            this.form
          )

          loading.close()

          if (result.error) return

          this.showNotification('Группа успешно создана', 'success')

          this.resetForm()

          this.$router.push(ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.LIST)
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.categories {
  padding: 1rem;
  label {
    margin: 0;
  }
}
</style>
